import React from 'react'
import { FileList } from './FileList'
import { SignOut } from './SignOut'
import styles from "./FileList.module.css";

type Props = {}

export const SimpleUser = (props: Props) => {
  return (
    <div className={styles.listContainer}>
        <span className={styles.premition}>Файли для завантаження</span>
        <SignOut />
        <FileList />
    </div>
  )
}