import React, { ChangeEvent, ChangeEventHandler } from "react";
import { FileList } from "./FileList";
import { SignOut } from "./SignOut";
import styles from "./FileList.module.css";

type Props = {};

export const UploaderUser = (props: Props) => {
  

  return (
    <div className={styles.listContainer}>
      <span className={styles.premition}>UploaderUser</span>
      
      
      <FileList />
      <SignOut />
    </div>
  );
};
