import React, { FC, useContext, useEffect, useState } from "react";
import { Auth } from "./auth";
import { SimpleUser } from "./SimpleUser";
import { UploaderUser } from "./UploaderUser";
import { UserType, UserTypeContext } from "./userTypeContext";
import styles from "./Home.module.css";

export const Home: FC = () => {
  //   const [userType, setUserType] = useState<string>();
  const { userType, setUserType } = useContext(UserTypeContext);
  // console.log("🚀 ~ file: Home.tsx:5 ~ userType:", userType);
  useEffect(() => {
    fetch("api/auth/user-type", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => (await res.text()) as UserType)
      .then(setUserType);
  }, []);

  const selectComponent = (type: string) => {
    switch (type) {
      case "empty":
        return <Auth />;
      case "simple":
        return <SimpleUser />;
      case "uploader":
        return <UploaderUser />;
    }
  };

  return (
    <div className={styles.conainer}>
      <div className={styles.homeStyle}>
        {userType && selectComponent(userType)}
      </div>
    </div>
  );
};
