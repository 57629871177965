import React, { FC, useContext, useRef } from "react";
import { UserType, UserTypeContext } from "../userTypeContext";
import styles from "./Auth.module.css";

export type AuthProps = {};

export const Auth: FC<AuthProps> = () => {
  const { setUserType } = useContext(UserTypeContext);
  const signIn = () => {
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;
    if (!username || !password) {
      return;
    }

    fetch("api/auth/sign-in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then(async (res) => (await res.text()) as UserType)
      .then(setUserType);
  };
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={styles.loginPass}>
      <div className={styles.input}>
        <span>Логін</span>
        <input ref={usernameRef} type="text" name="username" />
      </div>
      <div className={styles.input}>
        <span>Пароль</span>
        <input ref={passwordRef} type="text" name="password" />
      </div>
      <button className={styles.sumbit} onClick={signIn}>Вхід</button>
    </div>
  );
};
