// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_conainer__9UBvd {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 40px 0;\n    position:relative;\n    /* margin-top: 30vh; */\n}\n.Home_homeStyle__cldTC {\n    \n}\n", "",{"version":3,"sources":["webpack://./src/components/Home.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,iBAAiB;IACjB,sBAAsB;AAC1B;AACA;;AAEA","sourcesContent":[".conainer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 40px 0;\n    position:relative;\n    /* margin-top: 30vh; */\n}\n.homeStyle {\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conainer": "Home_conainer__9UBvd",
	"homeStyle": "Home_homeStyle__cldTC"
};
export default ___CSS_LOADER_EXPORT___;
