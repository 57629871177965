import { useEffect, useState } from "react";

export interface FetchOprions extends RequestInit {
  type?: "text" | "json" | "file";
  lazy?: boolean;
}

export const useFetch = <T = string>(
  url: string,
  options: FetchOprions = {}
) => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const { type = "text", lazy, ...init } = options;
  const handle = async (body?: string | object | FormData): Promise<T> => {
    setLoading(true);
    return new Promise(async (response) => {
      await fetch(`api/${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        ...init,
        body: body instanceof FormData ? body : typeof body === "object" ? JSON.stringify(body) : body,
      })
        .then(async (res) => {
          switch (type) {
            case "text":
              return res.text();
            case "json":
              return res.json();
            case "file": {
              const blob = await res.blob();
              const file = window.URL.createObjectURL(blob);
              return file;
              console.log(
                "🚀 ~ file: useFetch.ts:34 ~ .then ~ file:",
                file,
                blob
              );
              // window.location.assign(file);
              window.open(file, "_blank");
            }
          }
        })
        .then((res) => {
          setData(res);
          response(res);
        })
        .finally(() => {
          setLoading(false)
        });
        
    });
  };
  useEffect(() => {
    if (!lazy) {
      handle();
    }
  }, []);

  return { data, loading, handle };
};
