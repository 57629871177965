import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Home } from "./components/Home";
import { UserType, UserTypeContext } from "./components/userTypeContext";

function App() {
  const [userType, setUserType] = useState<UserType>("empty")
  useEffect(() => {
    document.title = 'ANDV';
  }, [])
  return (
    <UserTypeContext.Provider value={{userType, setUserType}}>
      <div className="App">
        <Home />
      </div>
    </UserTypeContext.Provider>
  );
}

export default App;
