import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useContext,
} from "react";
import { useFetch } from "./useFetch";
import { UserTypeContext } from "./userTypeContext";
import styles from "./FileList.module.css";


type FileListProps = {};

export const FileList: FC<FileListProps> = () => {
  const { userType } = useContext(UserTypeContext);
  const {
    data,
    loading,
    handle: handleFileList,
  } = useFetch<string[]>("files/file-list", {
    type: "json",
  });

  const {
    data: dataDelete,
    loading: loadingDelete,
    handle: handleDelete,
  } = useFetch<string[]>("files/delete-file", {
    type: "json",
    lazy: true,
  });

  const {
    data: dataGetFile,
    loading: loadingGetFile,
    handle: handleGetFile,
  } = useFetch<string>("files/get-file", {
    type: "file",
    lazy: true,
  });


  const {
    loading: loadingUploadFile,
    handle: handleUploadFile,
  } = useFetch<string>("files/upload", {
    type: "file",
    lazy: true,
    headers: {}
  });

  const onDelete = async (fileName: string) => {
    await handleDelete({ fileName });
    await handleFileList();
  };

  const uploadFile: ChangeEventHandler<HTMLInputElement> = async (event) => {
   
    const { files } = event.target;
    if (files?.length && files[0]) {
      const file = files[0];
      const body = new FormData();
      body.append("file", file);
      await handleUploadFile(body);
      await handleFileList();
    }
  };

  const getFile = async (fileName: string) => {
    const file = await handleGetFile({ fileName });
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = file;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(file);
  };

  return (
    <div className={styles.listcontainer}>
      {loadingUploadFile && <div>
          <div className={styles.spinner}><img src={process.env.PUBLIC_URL + '/spinner.gif'} /></div>
          Loading...
          </div>}
      <div className={styles.listitems}>
        
        {data &&
          data.map((fileName) => (
            <div key={fileName} className={styles.listitem}>
              <div
                onClick={() => getFile(fileName)}
                className={styles.filename}
              >
                {fileName}
              </div>
              <div className={styles.delete}>
                {userType === "uploader" && (
                  <button onClick={() => onDelete(fileName)}>delete</button>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className={styles.newFile}>
        {userType === "uploader" && (
          <input
            type="file"
            // accept="image/jpeg"
            // hidden
            onChange={uploadFile}
            onClick={(e: ChangeEvent<any>) => {
              e.target.value = null;
            }}
          />
        )}
      </div>
    </div>
  );
};