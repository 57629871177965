import React, { useContext } from "react";
import { UserType, UserTypeContext } from "./userTypeContext";
import styles from "./FileList.module.css";

export const SignOut = () => {
  const { setUserType } = useContext(UserTypeContext);
  const signOutHandler = () => {
    fetch("api/auth/sign-out", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => (await res.text()) as UserType)
      .then(setUserType);
  };
  return (
    <div className={styles.out}>
      <button onClick={signOutHandler}>Вийти</button>
    </div>
  );
};
